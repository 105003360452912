import React from "react";
import "./index.scss";

const Contacts = ({ currentLocale }) => {
  return (
    <div className="PgA-Contacts">
      <div className="container">
        <h2 className="PgA-Contacts__title">Get in Touch</h2>
        <div className="PgA-Contacts-block">
          <div className="PgA-Contacts-map" />
          <div className="PgA-Contacts-info">
            <p>AMZScout HQ</p>
            <p>
              1735 Market Street, Suite 3750,
              <br /> Philadelphia, PA 19103
            </p>
            <div className="PgA-Contacts-email">
              <span>
                <b>Email:</b>
              </span>
              <a href="mailto:support@amzsc.amzgb.com">support@amzsc.amzgb.com</a>
            </div>
          </div>
        </div>
        {currentLocale === "eu" && (
          <div className="PgA-Contacts-block PgA-Contacts-block-eu">
            <div className="PgA-Contacts-map" />
            <div className="PgA-Contacts-info">
              <p>Growthco DMCC</p>
              <p>
                Unit No: BA402
                <br /> DMCC Business Centre
                <br /> Level No 1<br /> Jewellery & Gemplex 3<br /> Dubai
                <br /> United Arab Emirates
              </p>
              <div className="PgA-Contacts-email">
                <span>
                  <b>Email:</b>
                </span>
                <a href="mailto:support@amzsc.amzgb.com">support@amzsc.amzgb.com</a>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Contacts;
